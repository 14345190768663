import { useEffect, useState } from 'react';
import { LDSingleKindContext } from 'launchdarkly-js-sdk-common';

import { useAuthState } from '@irc/shared-services-auth';

import { LaunchDarklyIdentify } from './LaunchDarklyIdentify';

type Props = {
  custom?: Record<string, unknown>;
  onDone?: () => void;
};

export const LaunchDarklyIdentifySessionUser = ({ custom, onDone }: Props) => {
  const { user } = useAuthState();
  const [context, setContext] = useState<LDSingleKindContext>();

  useEffect(() => {
    if (user) {
      const { profile } = user;
      setContext({
        kind: 'user',
        key: profile.email,
        name: profile.name,
        firstName: profile.given_name,
        lastName: profile.family_name,
        email: profile.email,
        ...custom
      });
    }
  }, [user, custom]);

  return <LaunchDarklyIdentify context={context} onDone={onDone} />;
};
