import { useCallback, useEffect, useState } from 'react';

interface Coords {
  accuracy: number;
  altitude: number | null;
  altitudeAccuracy: number | null;
  heading: number | null;
  latitude: number;
  longitude: number;
  speed: number | null;
}

interface GeolocationData {
  timestamp: number;
  coords: Coords;
}

const mapPositionToObject = ({
  timestamp,
  coords
}: GeolocationPosition): GeolocationData => ({
  timestamp,
  coords: {
    accuracy: coords.accuracy,
    altitude: coords.altitude,
    altitudeAccuracy: coords.altitudeAccuracy,
    heading: coords.heading,
    latitude: coords.latitude,
    longitude: coords.longitude,
    speed: coords.speed
  }
});

export const useGeolocation = () => {
  const [location, setLocation] = useState<GeolocationData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const getCurrentPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const mappedPos = mapPositionToObject(pos);
        setLocation(mappedPos);
      },
      (err) => {
        setError(err.message);
      },
      { timeout: 10000, enableHighAccuracy: true }
    );
  }, []);

  useEffect(() => {
    getCurrentPosition();
  }, []);

  return {
    location,
    error,
    getCurrentPosition
  };
};
