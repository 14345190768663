import { useEffect } from 'react';
import { LDSingleKindContext } from 'launchdarkly-js-sdk-common';
import { useLDClient } from 'launchdarkly-react-client-sdk';

type Props = {
  context?: LDSingleKindContext;
  onDone?: () => void;
};

export const LaunchDarklyIdentify = ({ context, onDone }: Props) => {
  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient && context) {
      ldClient.identify(context, undefined, onDone);
    }
  }, [ldClient, context, onDone]);

  return null;
};
