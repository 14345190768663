import React, { useEffect, useRef } from 'react';

import {
  AppLayout,
  Desktop,
  Mobile,
  notificationManager,
  useAppLayout,
  useAppState
} from '@irc/carli-components';
import {
  SearchField as SharedSearchField,
  SearchFieldResultType
} from '@irc/shared-components-search-field';
import { RequireAuthentication } from '@irc/shared-features-auth';
import { useFlags } from '@irc/shared-services-launch-darkly';
import { usePostLocationToVPS } from '@irc/shared-services-vps';
import { env } from '@irc/shared-utils-environment';
import {
  InputSource,
  type ScanDetectionChangeEventType,
  useScanDetection
} from '@irc/shared-utils-scan-detection';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { PageLayout } from '../../app/PageLayout';

const Root = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 0 ${({ theme }) => theme.spacing(2)};
`;

const SearchFieldWrapper = styled(Box)`
  text-align: center;
  padding: ${({ theme }) => theme.spacing(13)} 0;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(3)} 0;
  }
`;

const SearchField = styled(SharedSearchField)`
  width: 100%;
  max-width: 512px;
`;

export const Home = () => {
  const { isMobile } = useAppLayout();
  const [, setAppState] = useAppState();
  const LDFlags = useFlags();

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const { sendLocationToVPS } = usePostLocationToVPS({
    notificationManager,
    source: 'CarliSearch'
  });

  const handleInputSearch = ({
    value,
    inputSource
  }: ScanDetectionChangeEventType) => {
    if (inputSource === InputSource.Scanned) {
      sendLocationToVPS(value);
    }
    window.open(`${env.NX_PUBLIC_CARLI_SEARCH_REDIRECT}?searchTerm=${value}`);
    setSearchFieldState({ searchTerm: '' });
  };

  const {
    handleChange: onScanHandleChange,
    handlePaste,
    onInput
  } = useScanDetection(handleInputSearch);

  const onChangeView = (e: React.MouseEvent) => {
    e.preventDefault();
    const nextLayout = isMobile ? AppLayout.Desktop : AppLayout.Mobile;
    setAppState((prevState) => ({ ...prevState, layout: nextLayout }));
  };

  const [{ searchTerm }, setSearchFieldState] = React.useState<{
    type?: SearchFieldResultType;
    searchTerm: string;
  }>({ searchTerm: '' });

  const handleInputCancel = () => {
    setSearchFieldState({ searchTerm: '' });
  };
  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = evt.currentTarget.value;
    setSearchFieldState((prevState) => ({
      ...prevState,
      searchTerm
    }));
  };

  return (
    <RequireAuthentication>
      <PageLayout
        footerLinks={[
          {
            id: 'layout-link',
            onClick: onChangeView,
            label: isMobile ? 'View Desktop' : 'View Mobile'
          }
        ]}
      >
        <Root>
          <SearchFieldWrapper>
            <SearchField
              hiddenLabel
              variant='filled'
              placeholder='Search VIN or Stock#'
              data-qa='search-field'
              value={searchTerm}
              onChange={handleInputChange}
              onSearch={onScanHandleChange}
              onCancel={handleInputCancel}
              onInput={onInput}
              onPaste={handlePaste}
              inputRef={searchInputRef}
              autoComplete='off'
            />
          </SearchFieldWrapper>
          <Box>
            {isMobile ? (
              <Mobile flags={LDFlags} />
            ) : (
              <Desktop flags={LDFlags} />
            )}
          </Box>
        </Root>
      </PageLayout>
    </RequireAuthentication>
  );
};
