import { AppStateProvider, notificationManager } from '@irc/carli-components';
import { Snackbar } from '@irc/shared-components-notification';
import { Datadog } from '@irc/shared-features-analytics';
import {
  AuthClientProvider,
  RedirectClient as AuthClient,
  Scope
} from '@irc/shared-services-auth';
import { InlineManual } from '@irc/shared-services-inline-manual';
import { LaunchDarklyProvider } from '@irc/shared-services-launch-darkly';
import { ThemeProvider } from '@irc/shared-theme';
import { env } from '@irc/shared-utils-environment';
import { createQueryClient, QueryProvider } from '@irc/shared-utils-http';

import packageInfo from '../../package.json';
import { theme } from '../theme';

import { LaunchDarklyIdentify } from './LaunchDarklyIdentify';
import { Router } from './Router';

const LD_FLAGS = Object.freeze({
  Cardiac: false,
  CarliDR: false,
  CarliPartsPortal: false,
  CarInspect: false,
  PartsCart: false,
  PriceCheck: false,
  CarFix: false,
  Carburetor: false
});

export const App = () => {
  const queryClient = createQueryClient();

  const authClient = new AuthClient({
    authority: `${env.NX_PUBLIC_DOMAIN}/auth/identity`,
    clientId: 'cvna-irc-carli-ui',
    scope: [Scope.LocationRead, Scope.VTRAM, Scope.VehiclePositionWrite]
  });

  return (
    <LaunchDarklyProvider
      clientId={env.NX_PUBLIC_LAUNCH_DARKLY_KEY}
      flags={LD_FLAGS}
    >
      <AuthClientProvider client={authClient}>
        <Datadog
          trackUser
          enableSessionReplayRecording
          applicationId='b4bb635e-7a21-4558-9b59-1430497347db'
          clientToken='pubfa8e1022b50ca83ca01333ed6a74308b'
          service='irc-carli-ui'
          version={packageInfo.version}
        />
        <InlineManual apiKey={env.NX_PUBLIC_INLINE_MANUAL_KEY} />
        <ThemeProvider theme={theme}>
          <QueryProvider client={queryClient}>
            <AppStateProvider>
              <LaunchDarklyIdentify />
              <Router />
            </AppStateProvider>
          </QueryProvider>
          <Snackbar
            id={notificationManager.containerId}
            autoHide={2000}
            origin={{ vertical: 'bottom', horizontal: 'left' }}
          />
        </ThemeProvider>
      </AuthClientProvider>
    </LaunchDarklyProvider>
  );
};
