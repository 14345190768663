import {
  PopupWindowFeatures,
  SigninPopupArgs,
  SigninRedirectArgs,
  SigninSilentArgs,
  SignoutPopupArgs,
  SignoutRedirectArgs
} from 'oidc-client-ts';

export type WithScopeArray<T> = Omit<T, 'scope'> & {
  scope?: string[] | string;
};

export type SigninType = 'silent' | 'redirect' | 'popup';
export type SigninSilentState = WithScopeArray<SigninSilentArgs>;
export type SigninRedirectState = WithScopeArray<SigninRedirectArgs>;
export type SigninPopupState = WithScopeArray<SigninPopupArgs>;

export type SigninState =
  | SigninSilentState
  | SigninRedirectState
  | SigninPopupState;

export type SignoutType = 'redirect' | 'popup';
export type SignoutRedirectState = SignoutRedirectArgs;
export type SignoutPopupState = SignoutPopupArgs;
export type SignoutState = SignoutRedirectState | SignoutPopupState;

export enum Scope {
  Carburetor = 'carburetor_read_write',
  CarCheck = 'carcheck_read_write',
  CarInspect = 'carinspect_rw',
  CarParts = 'carparts_read_write',
  FUSS = 'filegateway_read_write',
  ICGateway = 'icgateway_read_write',
  IMSGateway = 'imsgateway',
  LARS = 'lars_read_write',
  LocationRead = 'location-read',
  MCCAS = 'mccas_read_write',
  Microservice = 'microservice',
  Partsmazon = 'partsmazon_read_write',
  PrintDelete = 'print_delete',
  Print = 'print_read_write',
  ProcurementGateway = 'procurementgateway_read_write',
  ProjectX = 'projectx_read_write',
  SBManage = 'sbmanage_read_write',
  SCMAdmin = 'scmadmin_read_write',
  SCPRead = 'scp-read',
  VehicleHistoryRead = 'vehiclehistory_read',
  VehicleIDLookupRead = 'vehicleidlookup-read',
  VehicleIDLookupWrite = 'vehicleidlookup-write',
  VehiclePositionWrite = 'vehicleposition_write',
  VehicleReturn = 'vehiclereturn_read_write',
  VehicleTrackingRead = 'vehicletracking-read',
  VTRAM = 'vtram_read_write',
  NUSS = 'notes_read_write'
}

export type Configuration<T extends object = object> = T & {
  authority: string;
  clientId: string;
  scope: Scope[];
  redirectUri?: string;
  popupWindowFeatures?: PopupWindowFeatures;
  signOutRedirectUri?: string;
  silentRedirectUri?: string;
};

export interface EventHandlers<U> {
  onAccessTokenExpired: () => Promise<void> | void;
  onAccessTokenExpiring: () => Promise<void> | void;
  onSilentRenewError: (error: Error) => Promise<void> | void;
  onUserLoaded: (user: U) => Promise<void> | void;
  onUserUnloaded: () => Promise<void> | void;
  onUserSignedOut: () => Promise<void> | void;
}
